// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../Link/A.res.js";
import * as Button from "../../Button/Button.res.js";
import * as Control from "../../Control/Control.res.js";
import * as IconCart from "../../../icons/IconCart.res.js";
import * as Routes_User from "../../../../routes/common/Routes_User.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as NavSignedOutScss from "./NavSignedOut.scss";

var css = NavSignedOutScss;

function NavSignedOut(props) {
  var toggleCart = props.toggleCart;
  switch (props.device) {
    case "Desktop" :
    case "Tablet" :
        break;
    case "Mobile" :
        return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                    children: [
                      JsxRuntime.jsx("li", {
                            children: JsxRuntime.jsx(A.make, {
                                  href: Routes_User.signIn,
                                  className: "flex flex-1 flex-shrink-0 w-full p-5 text-sm justify-center text-teal",
                                  children: "Sign In"
                                })
                          }),
                      JsxRuntime.jsx("li", {
                            children: JsxRuntime.jsx(Button.AsLink.make, {
                                  href: Routes_User.signUp,
                                  size: "SM",
                                  color: "Teal",
                                  expanded: true,
                                  children: "Sign Up"
                                })
                          })
                    ]
                  });
    
  }
  return JsxRuntime.jsxs("ul", {
              children: [
                JsxRuntime.jsx("li", {
                      children: JsxRuntime.jsxs(Control.make, {
                            className: css.link,
                            onClick: (function (param) {
                                toggleCart();
                              }),
                            children: [
                              JsxRuntime.jsx(IconCart.make, {
                                    size: "MD",
                                    color: "DarkGray",
                                    className: css.icon
                                  }),
                              "Cart",
                              JsxRuntime.jsx("span", {
                                    children: String(props.totalCartQuantity),
                                    className: css.cartCounter
                                  })
                            ]
                          })
                    }),
                JsxRuntime.jsx("li", {
                      children: JsxRuntime.jsx(A.make, {
                            href: Routes_User.signIn,
                            className: css.link,
                            children: JsxRuntime.jsx("strong", {
                                  children: "Sign In"
                                })
                          })
                    }),
                JsxRuntime.jsx("li", {
                      children: JsxRuntime.jsx(Button.AsLink.make, {
                            href: Routes_User.signUp,
                            size: "XS",
                            color: "Teal",
                            children: "Sign Up"
                          })
                    })
              ],
              className: css.topLinks
            });
}

var make = NavSignedOut;

export {
  css ,
  make ,
}
/* css Not a pure module */
