// extracted by mini-css-extract-plugin
export var buttonReserve = "CheckOut__buttonReserve__WjgXl";
export var cartButton = "CheckOut__cartButton__vWKDr";
export var cartNote = "CheckOut__cartNote__PRmCV";
export var column = "CheckOut__column__ptL3e";
export var content = "CheckOut__content__hJEwG";
export var flex = "CheckOut__flex__vM8os";
export var flexColumn = "CheckOut__flexColumn__Vh0_j";
export var gap1 = "CheckOut__gap1__I0UGP";
export var gap2 = "CheckOut__gap2__fHzc3";
export var gap3 = "CheckOut__gap3__lgtPF";
export var gap4 = "CheckOut__gap4__DmmAm";
export var gap5 = "CheckOut__gap5__Evn3s";
export var row = "CheckOut__row__ZhNf2";
export var title = "CheckOut__title__IcAMJ";
export var totalPrice = "CheckOut__totalPrice__xtLnE";