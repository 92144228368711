// extracted by mini-css-extract-plugin
export var adminLink = "NavDropdown__adminLink__ISN4M";
export var column = "NavDropdown__column__O3Ytx";
export var flex = "NavDropdown__flex__y314e";
export var flexColumn = "NavDropdown__flexColumn__zrMby";
export var gap1 = "NavDropdown__gap1__qpjHC";
export var gap2 = "NavDropdown__gap2__bD0Cq";
export var gap3 = "NavDropdown__gap3__NasPu";
export var gap4 = "NavDropdown__gap4__TEx7A";
export var gap5 = "NavDropdown__gap5__cmM6W";
export var menuClosed = "NavDropdown__menuClosed__SZ_vz";
export var menuOpened = "NavDropdown__menuOpened__dHAZ9";
export var mobileLink = "NavDropdown__mobileLink__YbqDT";
export var mobileList = "NavDropdown__mobileList__HyvDV";
export var primary = "NavDropdown__primary__zscpb";
export var row = "NavDropdown__row__U1IYw";
export var secondary = "NavDropdown__secondary__Q40HN";
export var submenu = "NavDropdown__submenu__iIzsV";
export var tabletLink = "NavDropdown__tabletLink__PjUq6";
export var tabletList = "NavDropdown__tabletList__WsqVQ";
export var topLinks = "NavDropdown__topLinks__rJGtZ";