// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../Link/A.res.js";
import * as Char from "../../../../libs/Char.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as NavDropdown from "../NavDropdown/NavDropdown.res.js";
import * as NavSignedIn from "../NavSignedIn/NavSignedIn.res.js";
import * as Routes_User from "../../../../routes/common/Routes_User.res.js";
import * as Routes_Order from "../../../../routes/common/Routes_Order.res.js";
import * as Routes_Country from "../../../../routes/common/Routes_Country.res.js";
import * as Routes_Product from "../../../../routes/common/Routes_Product.res.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import * as Routes_BlogPost from "../../../../routes/common/Routes_BlogPost.res.js";
import * as Routes_Location from "../../../../routes/common/Routes_Location.res.js";
import * as Routes_Provider from "../../../../routes/common/Routes_Provider.res.js";
import * as Routes_Resource from "../../../../routes/common/Routes_Resource.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Routes_SuspiciousIp from "../../../../routes/common/Routes_SuspiciousIp.res.js";
import * as Routes_SuspiciousEmail from "../../../../routes/common/Routes_SuspiciousEmail.res.js";
import * as MobileNavigationScss from "./MobileNavigation.scss";

var css = MobileNavigationScss;

function MobileSignedInNav(props) {
  var isNotOnlyProviderMember = props.isNotOnlyProviderMember;
  var userData = props.userData;
  var match = userData.role;
  var tmp;
  tmp = match === "Admin" ? JsxRuntime.jsx("li", {
          children: JsxRuntime.jsx(A.make, {
                href: Routes_User.Personal.Dashboard.notifications,
                className: css.submenuLink,
                children: "Notifications"
              })
        }) : null;
  var match$1 = userData.role;
  var tmp$1;
  var exit = 0;
  switch (match$1) {
    case "Admin" :
        tmp$1 = JsxRuntime.jsx(NavDropdown.make, {
              title: "Provider Admin",
              level: "Secondary",
              device: "Mobile",
              linksGroup: "MainLinks",
              children: JsxRuntime.jsxs("ul", {
                    children: [
                      JsxRuntime.jsx("li", {
                            children: JsxRuntime.jsxs(A.make, {
                                  href: Routes_Provider.Dashboard.index,
                                  className: css.submenuLink,
                                  children: [
                                    "Profiles ",
                                    JsxRuntime.jsx(Char.Ampersand.make, {}),
                                    " Listings"
                                  ]
                                })
                          }),
                      JsxRuntime.jsx("li", {
                            children: JsxRuntime.jsx(A.make, {
                                  href: Routes_Project.Dashboard.index,
                                  className: css.submenuLink,
                                  children: "Provider Projects"
                                })
                          }),
                      JsxRuntime.jsx("li", {
                            children: JsxRuntime.jsx(A.make, {
                                  href: Routes_Project.Dashboard.conciergeIndex,
                                  className: css.submenuLink,
                                  children: "Concierge Projects"
                                })
                          }),
                      JsxRuntime.jsx("li", {
                            children: JsxRuntime.jsx(A.make, {
                                  href: Routes_Product.Dashboard.index,
                                  className: css.submenuLink,
                                  children: "Marketplace Products"
                                })
                          }),
                      JsxRuntime.jsx("li", {
                            children: JsxRuntime.jsx(A.make, {
                                  href: Routes_Order.Dashboard.index,
                                  className: css.submenuLink,
                                  children: "Marketplace Orders"
                                })
                          })
                    ],
                    className: css.resetList
                  })
            });
        break;
    case "Provider" :
        tmp$1 = JsxRuntime.jsx(NavDropdown.make, {
              title: "Provider Admin",
              level: "Secondary",
              device: "Mobile",
              linksGroup: "MainLinks",
              children: JsxRuntime.jsx("ul", {
                    children: props.isConcierge ? (
                        isNotOnlyProviderMember ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                children: [
                                  JsxRuntime.jsx("li", {
                                        children: JsxRuntime.jsxs(A.make, {
                                              href: Routes_Provider.Dashboard.index,
                                              className: css.submenuLink,
                                              children: [
                                                "Profiles ",
                                                JsxRuntime.jsx(Char.Ampersand.make, {}),
                                                " Listings"
                                              ]
                                            })
                                      }),
                                  JsxRuntime.jsx("li", {
                                        children: JsxRuntime.jsx(A.make, {
                                              href: Routes_Project.Dashboard.index,
                                              className: css.submenuLink,
                                              children: "Provider Projects"
                                            })
                                      }),
                                  JsxRuntime.jsx("li", {
                                        children: JsxRuntime.jsx(A.make, {
                                              href: Routes_Project.Dashboard.conciergeIndex,
                                              className: css.submenuLink,
                                              children: "Concierge Projects"
                                            })
                                      }),
                                  JsxRuntime.jsx("li", {
                                        children: JsxRuntime.jsx(A.make, {
                                              href: Routes_Product.Dashboard.index,
                                              className: css.submenuLink,
                                              children: "Marketplace Products"
                                            })
                                      }),
                                  JsxRuntime.jsx("li", {
                                        children: JsxRuntime.jsx(A.make, {
                                              href: Routes_Order.Dashboard.index,
                                              className: css.submenuLink,
                                              children: "Marketplace Orders"
                                            })
                                      })
                                ]
                              }) : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                children: [
                                  JsxRuntime.jsx("li", {
                                        children: JsxRuntime.jsx(A.make, {
                                              href: Routes_Project.Dashboard.index,
                                              className: css.submenuLink,
                                              children: "Provider Projects"
                                            })
                                      }),
                                  JsxRuntime.jsx("li", {
                                        children: JsxRuntime.jsx(A.make, {
                                              href: Routes_Project.Dashboard.conciergeIndex,
                                              className: css.submenuLink,
                                              children: "Concierge Projects"
                                            })
                                      })
                                ]
                              })
                      ) : (
                        isNotOnlyProviderMember ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                children: [
                                  JsxRuntime.jsx("li", {
                                        children: JsxRuntime.jsxs(A.make, {
                                              href: Routes_Provider.Dashboard.index,
                                              className: css.submenuLink,
                                              children: [
                                                "Profiles ",
                                                JsxRuntime.jsx(Char.Ampersand.make, {}),
                                                " Listings"
                                              ]
                                            })
                                      }),
                                  JsxRuntime.jsx("li", {
                                        children: JsxRuntime.jsx(A.make, {
                                              href: Routes_Project.Dashboard.index,
                                              className: css.submenuLink,
                                              children: "Provider Projects"
                                            })
                                      }),
                                  JsxRuntime.jsx("li", {
                                        children: JsxRuntime.jsx(A.make, {
                                              href: Routes_Product.Dashboard.index,
                                              className: css.submenuLink,
                                              children: "Marketplace Products"
                                            })
                                      }),
                                  JsxRuntime.jsx("li", {
                                        children: JsxRuntime.jsx(A.make, {
                                              href: Routes_Order.Dashboard.index,
                                              className: css.submenuLink,
                                              children: "Marketplace Orders"
                                            })
                                      })
                                ]
                              }) : JsxRuntime.jsx("li", {
                                children: JsxRuntime.jsx(A.make, {
                                      href: Routes_Project.Dashboard.index,
                                      className: css.submenuLink,
                                      children: "Provider Projects"
                                    })
                              })
                      ),
                    className: css.resetList
                  })
            });
        break;
    case "User" :
    case "Visitor" :
        tmp$1 = null;
        break;
    case "Concierge" :
    case "Agent" :
        exit = 1;
        break;
    
  }
  if (exit === 1) {
    tmp$1 = JsxRuntime.jsx("li", {
          children: JsxRuntime.jsx(A.make, {
                href: Routes_Project.Dashboard.conciergeIndex,
                className: css.submenuLink,
                children: "Concierge Projects"
              })
        });
  }
  var match$2 = userData.role;
  var tmp$2;
  tmp$2 = match$2 === "Admin" ? JsxRuntime.jsx(NavDropdown.make, {
          title: "Site Admin",
          level: "Secondary",
          device: "Mobile",
          linksGroup: "MainLinks",
          children: JsxRuntime.jsxs("ul", {
                children: [
                  JsxRuntime.jsx("li", {
                        children: JsxRuntime.jsx(A.make, {
                              href: Routes_BlogPost.Dashboard.index,
                              className: css.submenuLink,
                              children: "News"
                            })
                      }),
                  JsxRuntime.jsx("li", {
                        children: JsxRuntime.jsx(A.make, {
                              href: Routes_Resource.Dashboard.index,
                              className: css.submenuLink,
                              children: "Resources"
                            })
                      }),
                  JsxRuntime.jsx("li", {
                        children: JsxRuntime.jsx(A.make, {
                              href: Routes_User.Dashboard.index,
                              className: css.submenuLink,
                              children: "Users"
                            })
                      }),
                  JsxRuntime.jsx("li", {
                        children: JsxRuntime.jsx(A.make, {
                              href: Routes_Country.Dashboard.index,
                              className: css.submenuLink,
                              children: "Countries"
                            })
                      }),
                  JsxRuntime.jsx("li", {
                        children: JsxRuntime.jsx(A.make, {
                              href: Routes_Location.Dashboard.index,
                              className: css.submenuLink,
                              children: "Locations"
                            })
                      }),
                  JsxRuntime.jsx("li", {
                        children: JsxRuntime.jsx(A.make, {
                              href: Routes_User.Dashboard.cloudRack,
                              className: css.submenuLink,
                              children: "CloudRack™ Users"
                            })
                      }),
                  JsxRuntime.jsx("li", {
                        children: JsxRuntime.jsx(A.make, {
                              href: Routes_SuspiciousEmail.Dashboard.index,
                              className: css.submenuLink,
                              children: "Suspicious Email"
                            })
                      }),
                  JsxRuntime.jsx("li", {
                        children: JsxRuntime.jsx(A.make, {
                              href: Routes_SuspiciousIp.Dashboard.index,
                              className: css.submenuLink,
                              children: "Suspicious IPs"
                            })
                      })
                ],
                className: css.resetList
              })
        }) : null;
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsx(NavDropdown.make, {
                        title: "My Account",
                        level: "Primary",
                        device: "Mobile",
                        linksGroup: "MainLinks",
                        children: JsxRuntime.jsxs("ul", {
                              children: [
                                JsxRuntime.jsx(NavDropdown.make, {
                                      title: "Personal",
                                      level: "Secondary",
                                      device: "Mobile",
                                      linksGroup: "MainLinks",
                                      children: JsxRuntime.jsxs("ul", {
                                            children: [
                                              JsxRuntime.jsx("li", {
                                                    children: JsxRuntime.jsx(A.make, {
                                                          href: Routes_User.Personal.Dashboard.cloudRack,
                                                          className: css.submenuLink,
                                                          children: "CloudRack™"
                                                        })
                                                  }),
                                              JsxRuntime.jsx("li", {
                                                    children: JsxRuntime.jsx(A.make, {
                                                          href: Routes_Project.index,
                                                          className: css.submenuLink,
                                                          children: "Projects"
                                                        })
                                                  }),
                                              JsxRuntime.jsx("li", {
                                                    children: JsxRuntime.jsx(A.make, {
                                                          href: Routes_User.Personal.Dashboard.orders,
                                                          className: css.submenuLink,
                                                          children: "Orders"
                                                        })
                                                  }),
                                              JsxRuntime.jsx("li", {
                                                    children: JsxRuntime.jsx(A.make, {
                                                          href: Routes_User.Personal.Dashboard.invitations,
                                                          className: css.submenuLink,
                                                          children: "Invitations"
                                                        })
                                                  }),
                                              tmp,
                                              JsxRuntime.jsx("li", {
                                                    children: JsxRuntime.jsx(A.make, {
                                                          href: Routes_User.Personal.Dashboard.edit,
                                                          className: css.submenuLink,
                                                          children: "Edit Profile"
                                                        })
                                                  })
                                            ],
                                            className: css.resetList
                                          })
                                    }),
                                tmp$1,
                                tmp$2,
                                JsxRuntime.jsx("li", {
                                      children: JsxRuntime.jsx(NavSignedIn.make, {
                                            setUserData: props.setUserData,
                                            device: "Mobile"
                                          })
                                    })
                              ],
                              className: css.resetList
                            })
                      }))
            });
}

var make = MobileSignedInNav;

export {
  css ,
  make ,
}
/* css Not a pure module */
