// extracted by mini-css-extract-plugin
export var buttonReserve = "CartList__buttonReserve__O5fvV";
export var cartButton = "CartList__cartButton__pm30z";
export var cartIcon = "CartList__cartIcon__GKGrm";
export var cartNote = "CartList__cartNote__zQmpW";
export var column = "CartList__column__gn5_Z";
export var content = "CartList__content__PDtY8";
export var emptyCartContent = "CartList__emptyCartContent__tWnH5";
export var expiredNotification = "CartList__expiredNotification__UGiIc";
export var flex = "CartList__flex__M2jbD";
export var flexColumn = "CartList__flexColumn__e0756";
export var gap1 = "CartList__gap1__gKkeG";
export var gap2 = "CartList__gap2__tkzE_";
export var gap3 = "CartList__gap3__IJlda";
export var gap4 = "CartList__gap4__A5n8F";
export var gap5 = "CartList__gap5__s_PwG";
export var row = "CartList__row__BmIPc";
export var title = "CartList__title__f_pEi";
export var totalPrice = "CartList__totalPrice__vP_y6";