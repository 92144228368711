// extracted by mini-css-extract-plugin
export var cartCounter = "DesktopNavigation__cartCounter__iHDvO";
export var column = "DesktopNavigation__column__kxoWi";
export var firstLevelAdminLink = "DesktopNavigation__firstLevelAdminLink__RSDwY";
export var firstLevelLink = "DesktopNavigation__firstLevelLink__IU10P";
export var flex = "DesktopNavigation__flex__vmVmy";
export var flexColumn = "DesktopNavigation__flexColumn__NEaQJ";
export var gap1 = "DesktopNavigation__gap1__fvMTP";
export var gap2 = "DesktopNavigation__gap2__nxEN9";
export var gap3 = "DesktopNavigation__gap3__LfUi7";
export var gap4 = "DesktopNavigation__gap4__rNrz2";
export var gap5 = "DesktopNavigation__gap5__tJHhd";
export var hasMenu = "DesktopNavigation__hasMenu__B1jSR";
export var hasMenuAdmin = "DesktopNavigation__hasMenuAdmin__Zf6pv";
export var icon = "DesktopNavigation__icon__Lra2M";
export var innerWrapper = "DesktopNavigation__innerWrapper__rQm3Q";
export var link = "DesktopNavigation__link__uJhxR";
export var logo = "DesktopNavigation__logo__rkuQa";
export var mainLinks = "DesktopNavigation__mainLinks__PnvTl";
export var mainNavigation = "DesktopNavigation__mainNavigation__DVMtm";
export var nested = "DesktopNavigation__nested__VeUz7";
export var outerWrapper = "DesktopNavigation__outerWrapper__ahR5V";
export var resetList = "DesktopNavigation__resetList__obe5K";
export var row = "DesktopNavigation__row__VUEGe";
export var submenu = "DesktopNavigation__submenu___EOri";
export var submenuLink = "DesktopNavigation__submenuLink__pnjnt";
export var submenuNested = "DesktopNavigation__submenuNested__nlSGN";
export var submenuNestedLast = "DesktopNavigation__submenuNestedLast__FWLbi";
export var topLinks = "DesktopNavigation__topLinks__lELXr";
export var topNavigation = "DesktopNavigation__topNavigation__pfmuz";
export var wide = "DesktopNavigation__wide__dbvE2";